<template>
  <base-layout-two
    page-title="Property"
    page-default-back-link="/properties"
    end-button-text="Edit"
    :end-button-url="`/properties/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>{{ property.name }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Property Details">
        <base-field-display label-text="Name">
          <p>{{ property.name }}</p>
        </base-field-display>

        <base-field-display label-text="Address">
          <p>
            {{ property.address_line_1 }}<br />
            {{ property.address_line_2 }}<br />
            {{ property.city }}, {{ property.state }} {{ property.zipcode
            }}<br />
            {{ property.country }}
          </p>
        </base-field-display>

        <base-field-display label-text="Timezone">
          <p>{{ property.timezone }}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      property: {},
    };
  },

  ionViewWillEnter() {
    this.fetchProperty();
  },

  methods: {
    async fetchProperty() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties/${this.$route.params.id}`)
        .then((response) => {
          this.property = response.data.property;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>